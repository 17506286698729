import { useEffect, useState } from 'react';

import { Progressbar } from 'atp-react-ui';

import RESULTS_MAPPING_APIKEY from '_mappers/api-result-mappers';

function ProgressBar({ value, label, main, currentObj, pageCount, resultProgress }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setProgress(value);
    }, 100);
  });

  const handleClassAssignment = () => {
    let val = '';
    if (main && currentObj?.defaultProgress) {
      val = 'main';
    } else if (currentObj?.results && !label) {
      val = 'main results';
    } else if (currentObj?.results && label) {
      if (RESULTS_MAPPING_APIKEY.get(label) != null) {
        const fd = localStorage.getItem('formData') ? JSON.parse(localStorage.getItem('formData')) : null;
        const api_key = RESULTS_MAPPING_APIKEY.get(label);
        let labelValue = null;
        if (fd) {
          labelValue = fd[api_key];
        }
        if (labelValue) {
          if (labelValue.includes(`I've passed`) && labelValue !== `I've passed some, but not all of them.`) {
            val = 'main completed';
          } else {
            val = 'main results';
          }
        }
      }
    }
    return val;
  };

  const appendZero = (v) => {
    if (v <= 9) {
      return `0${v}`;
    }
    return v;
  };

  const handleProgressCount = () => {
    if (!resultProgress) {
      let [page] = pageCount;

      page = page === 0 ? 1 : page;
      const count = appendZero(page);

      return {
        left: <span className="text-xs font-medium text-primary">{count}</span>,
        right: <span className="text-xs font-medium text-primary">{pageCount[1]}</span>,
      };
    }
    return null;
  };

  handleClassAssignment();

  const { left, right } = handleProgressCount();

  return (
    <div className="mt-4 flex max-w-full items-center justify-between gap-x-2">
      {left}
      <Progressbar className="min-w-0" value={progress || 0} />
      {right}
    </div>
  );
}

export default ProgressBar;
