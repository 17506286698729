import { ReactComponent as LightLogo } from '_static/assets/atp_white_icon.svg';

export function Layout({ children }) {
  return (
    <article className="h-screen bg-primary-x-light text-white">
      <main className="h-[calc(100vh_-_190px)] bg-primary">
        <section className="flex flex-col gap-2 h-full w-full p-8">
          <header className="flex h-16 justify-end">
            <LightLogo className="w-7 md:w-10" />
          </header>
          <div className="mx-auto flex flex-col md:flex-row gap-8 justify-center w-full max-w-[_720px]">{children}</div>
        </section>
      </main>
    </article>
  );
}
