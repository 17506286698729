import { useEffect, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import {
  Button,
  Card,
  CardBody,
  Form,
  FormCheckbox,
  FormField,
  FormInput,
  FormPatternInput,
  Text,
  twMerge,
  useBoolean,
} from 'atp-react-ui';
import creditCardType from 'credit-card-type';
import { useForm } from 'react-hook-form';

import CATEGORIES from '_mappers/category-mapper';
import ROUTES from '_navigation/routes';
import { ReactComponent as AllowedCards } from '_static/assets/allowedCards.svg';
import { apiClient, prepApiClient } from '_utils';
import { updateLocalStorage } from '_utils/actions';
import snackbar from '_utils/snackbar-actions';

import { TZ } from '../../../const';

import {
  boxStyle,
  cardPatternGenerator,
  checkoutSchema,
  defaultCVVPattern,
  defaultPattern,
  formItemStyle,
  getMonthYear,
  inputStyle,
} from './checkout-form-schema';

function CheckoutForm({ currentObj, leadObj, navigate, submitLeadQualification, handleGoogleEvent }) {
  const [isLoading, setLoading] = useBoolean();
  const [currentCard, setCurrentCard] = useState({
    pattern: defaultPattern,
    card_number_length: 16,
    code: { pattern: defaultCVVPattern, name: 'CVV', size: 3 },
  });

  const { mutateAsync } = useMutation({
    mutationFn: async (payload) =>
      apiClient.post({
        endpoint: 'ajaxHandler.php',
        payload,
      }),
  });

  const { mutateAsync: lqCheckoutMutation } = useMutation({
    mutationFn: async (payload) =>
      prepApiClient.post({ endpoint: 'lead-qualification/checkout/', payload, json: true }),
  });

  const form = useForm({
    resolver: zodResolver(checkoutSchema),
    mode: 'onChange',
    defaultValues: {
      name: `${leadObj.First_Name__c} ${leadObj.Name}`,
      card_number: '',
      expiry: '',
      cvv: '',
      postal_code: '',
      agree: false,
      email: leadObj.Email__c ?? '',
    },
  });

  const { getValues } = form;
  const cardInput = getValues('card_number');

  const updateLeadCreateAccount = async (userInfo, customerId, invoice) => {
    const obj = { ...userInfo, customerId, invoice: null };

    try {
      const res = await mutateAsync({ action: 'updateLeadCreateAccount', userInfo: obj, leadId: leadObj.Id });
      return res;
    } catch (e) {
      snackbar.error('Something went wrong');
    }
  };

  const handleSubmit = async ({ card_number, cvv, name, email, postal_code, expiry }) => {
    if (card_number.length !== currentCard.card_number_length) {
      form.setError('card_number', { message: 'Invalid Credit Card number' }, { shouldFocus: true });
      return;
    }
    if (cvv.length !== currentCard.code.size) {
      form.setError('cvv', { message: 'Invalid Security Code' }, { shouldFocus: true });
      return;
    }
    setLoading.setTrue();
    const { month, year } = getMonthYear(expiry);
    const campaign = localStorage.getItem('campaign') ? JSON.parse(localStorage.getItem('campaign')) : {};
    const userInfo = {
      id: leadObj.Id,
      email: email, //leadObj.Email__c,
      phone: leadObj.mobile,
      firstName: leadObj.First_Name__c,
      lastName: leadObj.Name,
      campaign,
      formName: 'Lead Qualification - Purchased Bridge Plan',
      timezone: TZ,
    };

    const cardData = {
      number: card_number,
      expdate_year: year,
      expdate_month: month,
      cvv: parseInt(cvv, 10),
      name: name,
      postcode: postal_code,
    };

    try {
      await updateLeadCreateAccount(userInfo, null, null);
    } catch (e) {
      console.log(e);
      setLoading.setFalse();
    }

    lqCheckoutMutation({
      api: '59EB35B6285CABA255B28DE5C7E12',
      userInfo,
      cardData,
    })
      .then((res) => {
        if (res?.success) {
          handleGoogleEvent('bp');
          updateLocalStorage('formData', { Email__c: email });
          updateLocalStorage('formData', { Stage__c: CATEGORIES.BP_PRE_BUY_PURCHASED });
          submitLeadQualification();
          navigate(ROUTES.BRIDGE_PLAN_PRE_BUY_PURCHASED.link);
        } else {
          snackbar.error('Something went wrong');
        }
        setLoading.setFalse();
      })
      .catch(function (e) {
        if ('message' in e) {
          const error = e.message;
          snackbar.error(error);
          if (error.toLowerCase().indexOf('credit card') > -1) {
            form.setError('card_number', { message: error }, { shouldFocus: true });
          }
        } else {
          snackbar.error('Something went wrong');
        }

        setLoading.setFalse();
      });
  };

  useEffect(() => {
    const card = creditCardType(cardInput);
    if (card?.length > 0) {
      const { pattern, card_number_length } = cardPatternGenerator(card[0]);
      setCurrentCard({
        pattern,
        card_number_length,
        code: { ...card[0].code, pattern: Array(card[0].code.size).fill('#').join('') },
      });
    }
  }, [cardInput]);

  return (
    <CardBody>
      <Form {...form}>
        <form noValidate onSubmit={form.handleSubmit(handleSubmit)}>
          <main className="flex flex-col">
            <Text as="h3" className="mt-4 font-semibold">
              Summary
            </Text>
            <section className="mt-4">
              <article className="flex flex-col">
                <div className="flex items-center justify-between">
                  <Text as="caption-medium" className="text-base">
                    Custom Bridge Plan
                  </Text>
                  <div>$19</div>
                </div>
                <div className="flex items-center justify-between">
                  <Text as="caption-medium">Sales tax (0.00%)</Text>
                  <div>$0</div>
                </div>
                <div className="mt-2 flex items-center justify-between border-t border-outline pt-2">
                  <Text as="caption-medium-bold" className="text-base">
                    Today’s Total
                  </Text>
                  <Text as="caption-medium-bold" className="text-base">
                    $19
                  </Text>
                </div>
              </article>

              <Text as="h3" className="mt-4 font-semibold">
                Payment
              </Text>
              <div className="mt-2">
                <Text as="caption" className="text-accent-light">
                  All transactions are secure and encrypted.
                </Text>
                <Text as="caption" className="mt-1 flex items-center gap-x-2 text-accent-light">
                  Cards Accepted: <AllowedCards className="relative h-5 w-48" />
                </Text>
              </div>
            </section>

            <section className="mt-4 flex flex-col gap-y-4">
              <div className={boxStyle}>
                <FormField
                  name="name"
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <FormInput
                        label="Name on Card"
                        formItemClassName={formItemStyle}
                        className={inputStyle}
                        placeholder="Name"
                        {...field}
                      />
                    );
                  }}
                />
              </div>

              <div className={boxStyle}>
                <FormField
                  name="card_number"
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <FormPatternInput
                        label="Card Number"
                        pattern={currentCard.pattern}
                        as="numeric"
                        placeholder="1234 1234 1234 1234"
                        className={inputStyle}
                        formItemClassName={formItemStyle}
                        onValueChange={field.onChange}
                        {...field}
                      />
                    );
                  }}
                />
              </div>

              <article className="flex w-full gap-x-4">
                <div className={twMerge(boxStyle, 'w-1/3')}>
                  <FormField
                    name="expiry"
                    control={form.control}
                    render={({ field }) => {
                      return (
                        <FormPatternInput
                          label="Expiry"
                          pattern="## / ##"
                          as="numeric"
                          placeholder="12 / 30"
                          className={inputStyle}
                          formItemClassName={formItemStyle}
                          onValueChange={field.onChange}
                          {...field}
                        />
                      );
                    }}
                  />
                </div>
                <div className={twMerge(boxStyle, 'w-1/3')}>
                  <FormField
                    name="cvv"
                    control={form.control}
                    render={({ field }) => {
                      return (
                        <FormPatternInput
                          label={currentCard.code.name}
                          pattern={currentCard.code.pattern}
                          as="numeric"
                          placeholder="123"
                          type="password"
                          className={inputStyle}
                          formItemClassName={formItemStyle}
                          onValueChange={field.onChange}
                          {...field}
                        />
                      );
                    }}
                  />
                </div>
                <div className={twMerge(boxStyle, 'w-1/3')}>
                  <FormField
                    name="postal_code"
                    control={form.control}
                    render={({ field }) => {
                      return (
                        <FormPatternInput
                          label="Postal Code"
                          pattern="#####"
                          labelClassName="truncate"
                          as="numeric"
                          placeholder="12345"
                          className={inputStyle}
                          formItemClassName={formItemStyle}
                          onValueChange={field.onChange}
                          {...field}
                        />
                      );
                    }}
                  />
                </div>
              </article>
            </section>

            <Text as="h3" className="mt-4 font-semibold">
              Contact
            </Text>

            <section className="mt-4">
              <div className={boxStyle}>
                <FormField
                  name="email"
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <FormInput
                        type="email"
                        className={inputStyle}
                        formItemClassName={formItemStyle}
                        label="Email address"
                        placeholder="abc@zyz.com"
                        {...field}
                      />
                    );
                  }}
                />
              </div>
            </section>

            <section className="mt-4">
              <div>
                <FormField
                  name="agree"
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <FormCheckbox
                        label="Bridge Plan is a one-time fee and is non-refundable."
                        checked={field.value}
                        onChange={field.onChange}
                      />
                    );
                  }}
                />
              </div>
            </section>

            <section className="mt-6 text-center">
              <Button
                showLoader={isLoading}
                size="lg"
                className="w-48 rounded-none text-xl font-bold disabled:border-primary-light disabled:bg-primary-light disabled:text-primary-medium"
                theme="primary"
                type="submit"
              >
                {currentObj?.buttonText}
              </Button>
            </section>
          </main>
        </form>
      </Form>
    </CardBody>
  );
}

function Checkout({ currentObj, leadObj, navigate, submitLeadQualification, handleGoogleEvent }) {
  return (
    <Card className="shadow-card-primary-light">
      <CardBody>
        <CheckoutForm
          currentObj={currentObj}
          leadObj={leadObj}
          navigate={navigate}
          submitLeadQualification={submitLeadQualification}
          handleGoogleEvent={handleGoogleEvent}
        />
      </CardBody>
    </Card>
  );
}

export default Checkout;
