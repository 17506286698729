import { useCallback, useEffect, useRef } from 'react';

import { useNavigate, Outlet, useLocation } from 'react-router-dom';

import CATEGORIES from '_mappers/category-mapper';
import CATEGORY_TO_RESUME_PATH_MAPPER from '_mappers/resuming-mapper';
import ROUTES from '_navigation/routes';
import { getParameterByName, getCookie, logger, setCookie, updateLocalStorage, initCampaignObj } from '_utils';
import dateTime from '_utils/date-time';
import googleAnalytics4 from '_utils/google-analytics-4';

import { LeadContext } from './lead-context';
import { useLeadQuery } from './use-lead-query';

export function LeadProvider() {
  const initRef = useRef(false);
  const nav = useNavigate();
  const { pathname } = useLocation();
  const paramLeadId = getParameterByName('lid');
  const cookieLeadId = getCookie('lid');
  const leadId = paramLeadId || cookieLeadId;
  const leadInfo = useLeadQuery({ action: 'getLeadQualificationInfo', leadId }, { skip: !leadId });
  const { isSuccess, data } = leadInfo;

  const navigate = useCallback(
    (page) => {
      setTimeout(() => {
        nav(page);
      }, 100);
    },
    [nav]
  );

  const initializeLQP = useCallback(
    (campaignObj, lsObj) => {
      localStorage.clear();
      lsObj.Stage__c = CATEGORIES.START_QUALIFY;
      lsObj.Stages_Completed__c = [lsObj.Stage__c].join(';');
      updateLocalStorage('formData', lsObj);
      updateLocalStorage('campaign', { ...campaignObj });
      updateLocalStorage('ui', { pageAnalytics: [ROUTES.QUALIFY.link] });
      navigate(ROUTES.QUALIFY.link);
    },
    [navigate]
  );

  const handleRestoreSession = useCallback(
    (resumingStagePath) => {
      const categoryObj = CATEGORY_TO_RESUME_PATH_MAPPER.find(
        (item) => item.category.toLowerCase() === resumingStagePath.toLowerCase()
      );

      if (!categoryObj) {
        navigate(ROUTES.BRIDGE_RESULTS.link);
        return;
      }

      navigate(categoryObj.link);
    },
    [navigate]
  );

  if (!initRef.current && isSuccess && data && data.l && leadId) {
    initRef.current = true;
    // set logger id
    logger.setId({ lead_id: leadId });

    // if param and cookie lead id are different, reset local storage
    if (paramLeadId !== cookieLeadId) {
      setCookie('lid', leadId, 365);
      updateLocalStorage('formData', {});
      updateLocalStorage('campaign', {});
      updateLocalStorage('ui', {});
    }

    const { l, lq } = data;
    const { Email__c, Id } = l;
    const lsObj = { Email__c, Lead__c: Id };
    const campaignObj = initCampaignObj();
    let hasFiveMinExpired = false;

    // initialize local storage if LQ is not available
    if (!lq) {
      initializeLQP(campaignObj, lsObj);
    } else {
      const { Last_Stage_Completed_TimeStamp__c, Stage__c } = lq;
      const lastUpdatedTime = dateTime.parseToUtc(Last_Stage_Completed_TimeStamp__c);

      if (dateTime.getDurationDifference(dateTime.now(true), lastUpdatedTime, 'minute', false) > 5) {
        hasFiveMinExpired = true;
      }

      const lqFilter = new Set(['Id', 'attributes']);
      for (const [key, val] of Object.entries(lq)) {
        if (!lqFilter.has(key)) {
          lsObj[key] = val;
        }
      }

      // const setLqStages = // new Set([...Stages_Completed__c.split(';')]);
      //   setLqStages.delete(CATEGORIES.MINDSET_REPATH_NOT_READY);
      // const lqStages = Stage__c;
      // lqStages.pop();

      updateLocalStorage('formData', lsObj);
      updateLocalStorage('campaign', { ...campaignObj });

      // restore session only when not in qualify page
      // const resumingStagePath = [ROUTES.QUALIFY.link].includes(pathname) ? null : pathname;

      if (hasFiveMinExpired) {
        // if 5 minutes has passed, reset the session

        localStorage.clear();
        lsObj.Stage__c = CATEGORIES.START_QUALIFY;
        updateLocalStorage('formData', lsObj);
        updateLocalStorage('campaign', { ...campaignObj });
        updateLocalStorage('ui', { pageAnalytics: [ROUTES.QUALIFY.link] });
        navigate(ROUTES.QUALIFY.link);
      } else if ([ROUTES.QUALIFY.link].includes(pathname)) {
        let lqStage = Stage__c;
        // legacy handling
        if ([CATEGORIES.BRIDGE_PLAN_PURCHASED, CATEGORIES.BP_PRE_BUY_PURCHASED].includes(lqStage)) {
          lqStage = CATEGORIES.BP_PRE_BUY_PURCHASED;
        }
        handleRestoreSession(lqStage);
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      googleAnalytics4.update(pathname);
    }, 100);
  }, [pathname]);

  return (
    <LeadContext.Provider value={{ ...leadInfo, navigate }}>
      <Outlet />
    </LeadContext.Provider>
  );
}
