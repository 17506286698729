import { useEffect, useRef } from 'react';

import { InlineWidget, useCalendlyEventListener } from 'react-calendly';

import CATEGORIES from '_mappers/category-mapper';
import { calendlyCalendarUrl, calendlyPreBuyUrl } from 'const';

function CalendlyView({ leadObj, currentObj, updateCategory, submitLeadQualification }) {
  const ref = useRef(null);
  const isPreBuy = currentObj?.category === CATEGORIES.BP_PRE_BUY_RES_SCHEDULE;

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      if (currentObj?.category) {
        updateCategory(currentObj?.category);
        submitLeadQualification();
        ref.current = true;
      }
    },
  });

  const prefill = {
    name: `${leadObj.First_Name__c} ${leadObj.Name}`,
    email: leadObj.Email__c,
    customAnswers: {
      a1: `1${leadObj.Mobile_Primary__c}`,
    },
  };
  const pageSettings = {
    primaryColor: 'FF4500',
  };

  const handleEvent = () => {
    if (ref?.current) {
      updateCategory(currentObj?.nextCategory);
      submitLeadQualification();
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleEvent);

    return () => {
      window.removeEventListener('beforeunload', handleEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InlineWidget
      url={isPreBuy ? calendlyPreBuyUrl : calendlyCalendarUrl}
      prefill={prefill}
      pageSettings={pageSettings}
      styles={{ height: 'calc(100vh - 128px)' }}
    />
  );
}

export default CalendlyView;
