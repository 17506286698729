import ArrowRightIcon from '@heroicons/react/24/outline/ArrowRightIcon';
import { Button, Card, CardBody, twMerge } from 'atp-react-ui';

import { ReactComponent as InfoIcon } from '_static/assets/infoQuestionIcon.svg';
import { STYLE } from '_static/styles';
import { replaceWithName } from '_utils/actions';

function InformativeCard({ currentObj, leadObj }) {
  const handleRenderCustom = (key) => {
    switch (key) {
      case 'rejectInfo':
        return <RejectInfo currentObj={currentObj} />;
      default:
        return null;
    }
  };

  return (
    <>
      <article className={currentObj?.bubbleChat ? STYLE.bubbleChat : STYLE.header}>
        {replaceWithName(currentObj.header, leadObj)}
      </article>
      {/* eslint-disable-next-line */}
      <div className={twMerge(STYLE.subHeader, 'mt-5')} dangerouslySetInnerHTML={{ __html: currentObj.subheader }} />
      {handleRenderCustom(currentObj?.customKey)}
    </>
  );
}

function RejectInfo({ currentObj }) {
  return (
    <>
      <Card className="mt-8 bg-primary-x-medium shadow-none">
        <CardBody className="flex flex-row">
          <div className="mr-3">
            <InfoIcon />
          </div>
          <div>
            {currentObj?.info}
            <div className="mt-4 flex">
              You have options.
              <Button
                as="link"
                size="xs"
                tag="a"
                iconRight={ArrowRightIcon}
                theme="primary"
                href={currentObj?.infoLink}
                target="blank"
              >
                Learn more
              </Button>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
}

export default InformativeCard;
