import { SupportedServerTypes } from 'atp-api-logger';

import { REACT_APP_API_BASE_URL, REACT_APP_CONNECTED_PORTAL_URL } from 'const';

import { logger } from './logger';

export class ApiClient {
  constructor({ baseUrl }) {
    this.baseUrl = baseUrl;
  }

  // eslint-disable-next-line class-methods-use-this
  objectToQueryParam(paramsObj) {
    if (!paramsObj) {
      return '';
    }
    const obj = {};

    Object.keys(paramsObj).forEach((oKey) => {
      if (paramsObj[oKey] !== undefined && paramsObj[oKey] !== null) {
        obj[oKey] = `${paramsObj[oKey]}`;
      }
    });

    return new URLSearchParams(obj).toString();
  }

  async baseRequest({ init, endpoint, method = 'GET', body, asFormData = false, json = false, params, baseUrl }) {
    const queryString = this.objectToQueryParam(params);

    const urlWithParams = `${baseUrl || this.baseUrl}${endpoint ?? ''}?nocache=${new Date().getTime()}${queryString ?? ''}`;

    let requestBody = JSON.stringify(body);
    if (asFormData) {
      const formData = new FormData();

      // Iterate over the body object and set formData
      for (const key in body) {
        if (Object.hasOwnProperty.call(body, key)) {
          formData.append(key, body[key]);
        }
      }

      requestBody = formData;
    }

    const promise = fetch(urlWithParams, {
      ...init,
      headers: {
        ...(init?.headers || {}),
        ...(asFormData ? {} : { 'Content-Type': 'application/x-www-form-urlencoded' }),
        ...(json ? { 'Content-Type': 'application/json' } : {}),
      },
      method,
      body: requestBody,
    });

    logger.trackPromise(promise, {
      api_name: `${endpoint.toString()}${body.action ? `/${body.action}` : ''}`,
      method,
      server_type: SupportedServerTypes.COLLEGE_BRIDGE,
    });

    return promise;
  }

  async parseApiError(res) {
    let errorMessage = 'An unknown error occurred.';

    try {
      const errorResponse = await res.json();

      if ('errors' in errorResponse) {
        errorMessage = errorResponse.errors;
      }
    } catch (error) {
      if (res.status) {
        errorMessage = res.statusText;
      } else {
        errorMessage = error.toString();
      }
    }
    return new Error(errorMessage);
  }

  async makeRequest(params) {
    // Base request call
    let res = await this.baseRequest(params);

    if (!res.ok) {
      throw await this.parseApiError(res);
    }
    if (res.status === 204) {
      // Empty response
      return res;
    }
    const response = await res.json();
    // this.validateResponse<T>(response, responseSchema);
    return response;
  }

  async request({ params, init = {}, ...rest }) {
    return this.makeRequest({
      params,
      init,
      ...rest,
    });
  }

  async post({ payload, init = {}, method = 'POST', ...rest }) {
    let headers = init.headers || {};

    return this.makeRequest({
      method,
      body: payload,
      init: {
        ...init,
        headers: {
          ...headers,
        },
      },
      ...rest,
    });
  }
}

export const apiClient = new ApiClient({ baseUrl: `${REACT_APP_API_BASE_URL}/` });
export const prepApiClient = new ApiClient({ baseUrl: `${REACT_APP_CONNECTED_PORTAL_URL}/api/v1/` });
