const { REACT_APP_GOOGLE_4_TRACKING } = process.env;

let ga4Initialized = false;
const ga4EventsPipe = [];
let ga4Timer = null;

const update = (location) => {
  import('react-ga4').then((GA4) => {
    const ReactGA = GA4.default;

    if (!ga4Initialized) {
      ReactGA.initialize(REACT_APP_GOOGLE_4_TRACKING, {
        testMode: false,
      });
      ga4Initialized = true;
    }

    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  });
};

const event = (evtData) => {
  ga4EventsPipe.push(evtData);
  if (!ga4Initialized) return;

  import('react-ga4').then((GA4) => {
    const ReactGA = GA4.default;

    if (!ga4Timer) {
      ga4Timer = setInterval(() => {
        if (ga4EventsPipe.length > 0) {
          const evt = ga4EventsPipe.shift();
          try {
            ReactGA.event(evt);
          } catch (e) {
            console.log(e);
          }
        } else {
          clearInterval(ga4Timer);
          ga4Timer = null;
        }
      }, 2000);
    }
    return undefined;
  });
};

const googleAnalytics4 = {
  update,
  event,
};

export default googleAnalytics4;
