import { twMerge } from 'tailwind-merge';

import { ReactComponent as CircleArrow } from '_static/assets/circleArrow.svg';
import { STYLE } from '_static/styles';
import { replaceWithName } from '_utils/actions';

import useStyles from './styles';

function Italic() {
  return (
    <div>
      Purchase your own custom Bridge Plan <span className="font-medium text-base italic">– only $29.</span>
    </div>
  );
}

function CustomBodyCard({ currentObj, leadObj, navigate, currentPage }) {
  const classes = useStyles();
  const rsv1 = currentObj?.customKey === 'step1';
  const rsv2 = currentObj?.customKey === 'step2';

  const handleNavClick = (e) => {
    e.preventDefault();
  };
  const reservationSteps = () => {
    const data = [
      {
        step: 'Step One',
        body: <Italic />,
        icon: <CircleArrow />,
      },
      {
        step: 'Step Two',
        body: 'Meet with an Advisor to build your custom Bridge Plan.',
        icon: <CircleArrow />,
      },
    ];

    return (
      <>
        {data.map((card) => {
          return (
            <div key={card.step} className={classes.cardContainer}>
              <button
                type="button"
                className={`${classes.cardBtn} ${
                  (rsv1 && card.step === 'Step One') || (rsv2 && card.step === 'Step Two')
                    ? null
                    : classes.disableCardBtn
                }`}
                onClick={(e) => handleNavClick(e)}
              >
                <table className={`${classes.table} card`}>
                  <tbody>
                    <tr>
                      <td className="p-0 w-48 relative">
                        <div className={classes.cardTableHeader}>{card.step}</div>
                        <div className={classes.cardTableContent}>{card.body}</div>
                      </td>
                      <td className="text-right">{card.icon}</td>
                    </tr>
                  </tbody>
                </table>
              </button>
            </div>
          );
        })}
      </>
    );
  };

  const keysteps = () => {
    const data = [
      {
        id: '1',
        header: ['1. GENERAL EDUCATION COURSES'],
        subheader: 'Pass Gen Ed courses with credit by exam',
      },
      {
        id: '2',
        header: ['2. ENTRANCE EXAMS', ' (teas, hesi, kaplan)'],
        subheader: 'Pass your entrance exams the first time',
      },
      {
        id: '3',
        header: ['3. CORE NURSING COURSES'],
        subheader: 'On-demand tutoring and support',
      },
      {
        id: '4',
        header: ['4. EXIT', ' (nclex)'],
        subheader: 'Pass your exit exams the first time',
      },
    ];

    return (
      <ul className="p-0">
        {data.map((li) => {
          const { header, subheader, id } = li;
          const header1 = header[0];
          const header2 = header[1];
          return (
            <li className={classes.list} key={id}>
              <div>
                {header1}
                <span className={classes.smallHeader}>{header2}</span>
              </div>
              <div className={classes.subheader2}>{subheader}</div>
            </li>
          );
        })}
      </ul>
    );
  };
  const genedtable = () => {
    const data = [
      {
        // name: 'Anatomy & Physiology',
        name: 'College Algebra',
        subject: 'History of the US',
      },
      {
        name: 'College Mathematics',
        subject: 'Humanities',
      },
      {
        name: 'College Composition',
        subject: 'Introduction to Philosophy',
      },
      {
        name: 'Developmental Psychology',
        subject: 'Introduction to Psychology',
      },
      {
        name: 'English Composition',
        subject: 'Introduction to Sociology',
      },
      // {
      //   name: 'Foreign Language',
      //   subject: 'Microbiology',
      // },
      {
        name: 'Foreign Language',
        subject: 'Statistics',
      },
    ];
    return (
      <>
        <div className={classes.containTable}>
          <div className={classes.tableHeader}>Common Gen Eds</div>
          <table className={classes.table}>
            <tbody>
              {data.map((li, index) => {
                const { name, subject } = li;
                const isLast = index === data.length - 1;
                return (
                  <tr key={name}>
                    <td className={twMerge(classes.customTd, isLast && 'border-b')}>{name}</td>
                    <td
                      className={twMerge(
                        classes.customTd,
                        name === 'History of the US' ? classes.customTableText : null,
                        isLast && 'border-b'
                      )}
                    >
                      {subject}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className={twMerge(STYLE.subHeader, 'mt-6')}>{currentObj?.subheader}</div>
      </>
    );
  };

  const bridgePlan = () => {
    return <div>test</div>;
  };

  const renderHtml = (customBody) => {
    switch (customBody) {
      case 'rsv':
        return reservationSteps();
      case '4 key steps':
        return keysteps();
      case 'gen ed table':
        return genedtable();
      case 'bridge plan':
        return bridgePlan();
      default:
        return null;
    }
  };

  console.log('currentObj', currentObj);
  return (
    <>
      {rsv1 || rsv2 ? (
        <div className={classes.stepHeader}>{currentPage === '6_0' ? 'ONLY ONE STEP LEFT' : 'ONLY TWO STEPS LEFT'}</div>
      ) : null}
      <div
        className={`${currentObj?.bubbleChat ? STYLE.bubbleChat : classes.header} 
        ${(rsv1 || rsv2) && 'resvSteps'} ${!currentObj.header ? 'hidden' : ''}`}
      >
        {replaceWithName(currentObj.header, leadObj)}
      </div>
      {renderHtml(currentObj?.customBody)}
    </>
  );
}

export default CustomBodyCard;
