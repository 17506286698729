import { twMerge } from 'tailwind-merge';

import ROUTES from '_navigation/routes';
import { ReactComponent as AsteriskIcon } from '_static/assets/asterisk_logo.svg';
import { ReactComponent as AvatarLines } from '_static/assets/avatarlines.svg';
import { ReactComponent as CongratsFlagIcon } from '_static/assets/congratsFlagIcon.svg';
import { ReactComponent as CoreNursing } from '_static/assets/coreNursing.svg';
import { ReactComponent as Entrance } from '_static/assets/newEntranceExam.svg';
import { ReactComponent as Exit } from '_static/assets/newExitExam.svg';
import { ReactComponent as GenEd } from '_static/assets/newGeneralEducationIcon.svg';
import rebecca from '_static/assets/rebecca.jpg';
import { STYLE } from '_static/styles';
import { replaceWithName } from '_utils/actions';

import DangerousText from '../DangerousText';

import useStyles from './styles';

function MainCard({ currentObj, currentPage, leadObj }) {
  const classes = useStyles();

  const renderhtml = () => {
    return (
      <div className="text-center mt-[70px] mr-[40px] mb-[40px]">
        <span
          className="inline-block bg-white w-[120px] h-[120px] rounded-full relative"
          style={{ backgroundImage: `url(${rebecca})`, backgroundSize: 'contain' }}
        >
          <AvatarLines className="absolute -top-[30px] -left-[84px] w-[190px] h-[70px]" />
        </span>
      </div>
    );
  };
  const handleRenderIcon = (step) => {
    switch (step) {
      case 'Bridge 1/4':
        return <GenEd className={classes.stepIcon} />;
      case 'Bridge 2/4':
        return <Entrance className={classes.stepIcon} />;
      case 'Bridge 3/4':
        return <CoreNursing className="w-[90px] mt-5 mb-2.5 lg:w-[110px] lg:mb-0" />;
      case 'Bridge 4/4':
        return <Exit className={classes.stepIcon} />;
      case 'Congrats':
        return <CongratsFlagIcon className="w-[60px] h-auto lg:w-[75px]" />;
      default:
        return <AsteriskIcon className="w-[48px] h-auto lg:w-[60px]" />;
    }
  };
  const html = renderhtml();
  const renderIcon = handleRenderIcon(currentObj?.step);
  return (
    <>
      {renderIcon}
      <div
        className={twMerge(
          STYLE.header,
          'text-white lg:font-medium xl:font-medium mt-2.5 lg:mt-5',
          currentObj?.step && currentPage !== ROUTES.BRIDGE_PLAN_PRE_BUY_PURCHASED.link
            ? `${classes.mainStep} mb-2`
            : ''
        )}
      >
        <DangerousText>{replaceWithName(currentObj.header, leadObj)}</DangerousText>
      </div>
      <div
        className={twMerge(
          STYLE.header,
          'text-white xl:text-2xl text-xl font-sans',
          currentObj?.step && currentPage !== ROUTES.BRIDGE_PLAN_PRE_BUY_PURCHASED.link
            ? `${classes.mainStep} xl:text-xl font-normal xl:leading-10 m-0`
            : ''
        )}
      >
        <DangerousText>{currentObj.subheader}</DangerousText>
      </div>
      {currentObj?.mainStep === 'Step 2' ? html : null}
    </>
  );
}

export default MainCard;
