import { Suspense, lazy } from 'react';

import { ErrorBoundary } from '_components';

import { Layout } from './layout';
import { Loading } from './loading';

const MeetingConfirmation = lazy(() => import('./meeting-confirmation'));

export function MeetingConfirmationPage() {
  return (
    <Layout>
      <ErrorBoundary>
        <Suspense fallback={<Loading />}>
          <MeetingConfirmation />
        </Suspense>
      </ErrorBoundary>
    </Layout>
  );
}
