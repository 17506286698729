import { useEffect, useState } from 'react';

function TextArea({ currentObj, field, pageCallBack }) {
  const [formValue, setFormValue] = useState('');

  useEffect(() => {
    let formData = localStorage.getItem('formData') ? localStorage.getItem('formData') : null;
    if (formData) {
      formData = JSON.parse(formData);
      for (const [key, value] of Object.entries(formData)) {
        if (key === currentObj.api) {
          if (value) {
            setFormValue(value);
          }
        }
      }
    }
  }, [currentObj.api]);
  const handleOnChange = (e) => {
    const val = e.target.value;
    const formData = localStorage.getItem('formData') ? JSON.parse(localStorage.getItem('formData')) : {};
    let keyExist = false;
    if (formData.length > 0) {
      for (const [key, value] of Object.entries(formData)) {
        if (key === currentObj.api) {
          formData[key] = value;
          keyExist = true;
        }
      }
    } else if (!keyExist) {
      formData[currentObj.api] = val;
    }
    if (val.length > 0) {
      localStorage.setItem('formData', JSON.stringify(formData));
      setFormValue(val);
      pageCallBack(currentObj.api, val);
    } else {
      setFormValue(val);
    }
  };
  const handleNumberOfRows = () => {
    const width = window.innerWidth;
    let val = 6;
    if (width >= 1280 && width < 1920) {
      val = 9;
    } else if (width >= 1920) {
      val = 5;
    }
    return val;
  };
  const numberOfRows = handleNumberOfRows();

  return (
    <div className="p-4 bg-white shadow-input-primary-light rounded-lg rounded-tl-none">
      <textarea
        className="rounded-none p-0 text-primary-medium-dark leading-5 resize-none outline-none focus:border-none border-none w-full h-auto focus:outline-none focus:ring-0 placeholder-primary-medium shadow-none"
        placeholder={field?.placeholder}
        onChange={(e) => handleOnChange(e)}
        value={formValue}
        rows={numberOfRows}
      />
    </div>
  );
}

export default TextArea;
