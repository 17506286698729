import { useEffect } from 'react';

import { Form, FormField, FormInput, FormPhoneNumberInput, /* Input, Label, PatternInput,*/ Text } from 'atp-react-ui';

import { boxStyle, formItemStyle, inputStyle } from '_pages/components/Checkout/checkout-form-schema';
import { updateLocalStorage } from '_utils/actions';

function Contact({ form }) {
  const [email, phone] = form.getValues(['email', 'phone']);

  useEffect(() => {
    updateLocalStorage('formData', { Email__c: email });
    updateLocalStorage('formData', { mobile: phone });
  }, [email, phone]);

  return (
    <>
      <Form {...form}>
        <form>
          <section className="mt-2.5 flex flex-col gap-y-4">
            <div className={boxStyle}>
              <FormField
                name="email"
                control={form.control}
                render={({ field }) => {
                  return (
                    <FormInput
                      type="email"
                      className={inputStyle}
                      formItemClassName={formItemStyle}
                      label="Email address"
                      placeholder="abc@zyz.com"
                      {...field}
                    />
                  );
                }}
              />
            </div>

            <div className={boxStyle}>
              <FormField
                name="phone"
                control={form.control}
                render={({ field }) => {
                  return (
                    <FormPhoneNumberInput
                      className={inputStyle}
                      formItemClassName={formItemStyle}
                      label="Phone number"
                      placeholder="111 111-1111"
                      onValueChange={field.onChange}
                      {...field}
                    />
                  );
                }}
              />
            </div>
          </section>
        </form>
      </Form>

      <Text as="caption" className="mt-4 italic text-accent-light">
        We will use your information to provide you nursing bridge guidance tailored to your interests.You may
        unsubscribe at anytime.
      </Text>
    </>
  );
}

export default Contact;
