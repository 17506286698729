import { useEffect, useState } from 'react';

import ArrowRightIcon from '@heroicons/react/24/outline/ArrowRightIcon';
import ChevronLeftIcon from '@heroicons/react/24/outline/ChevronLeftIcon';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { Button } from 'atp-react-ui';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import CATEGORIES from '_mappers/category-mapper';
import GOOGLE_EVENT_MAPPING from '_mappers/google-mappers';
import ROUTES from '_navigation/routes';
import { useLeadContext } from '_provider';
import { ReactComponent as DarkLogo } from '_static/assets/atp_dark_icon.svg';
import { ReactComponent as LightLogo } from '_static/assets/atp_white_icon.svg';
import {
  apiClient,
  buildScoreMap,
  updateLocalStorage,
  getCurrentObj,
  getCurrentQuestions,
  getTimeValidity,
  useUrlParams,
  openWindow,
} from '_utils';
import googleAnalytics4 from '_utils/google-analytics-4';
import snackbar from '_utils/snackbar-actions';
import useWindowDimensions from '_utils/use-window-dimensions';

import { TZ } from '../const';

import CalendlyView from './components/CalendlyView';
import Checkout from './components/Checkout';
import CustomBodyCard from './components/CustomBodyCard';
import FormCard from './components/FormCard';
import { contactSchema } from './components/FormCard/contact-card-schema';
import InformativeCard from './components/InformativeCard';
import MainCard from './components/MainCard';
import PageCard from './components/PageCard';
import ProgressBar from './components/ProgressBar';
import ResultsCard from './components/ResultsCard';
import VideoCard from './components/VideoCard';

export default function BridgeQualify() {
  const nav = useNavigate();
  const { dev_setPortal } = useUrlParams();
  const { pathname: currentPage } = useLocation();
  const currentObj = getCurrentObj(currentPage);
  const currentQuestions = getCurrentQuestions(currentPage);
  const sendGoogleEvent = googleAnalytics4.event;
  const { navigate, data } = useLeadContext();
  const { isValidTimeToMeet } = getTimeValidity(dev_setPortal);

  const { l: leadObj } = data;

  const props = {
    navigate,
    currentObj,
    currentPage,
    leadObj,
    currentQuestions,
    sendGoogleEvent,
  };

  const { mutateAsync: mutateLeadQualification } = useMutation({
    mutationFn: async (payload) =>
      apiClient.post({
        endpoint: 'ajaxHandler.php',
        payload,
      }),
  });

  const { mutateAsync: mutateUpdateLead } = useMutation({
    mutationFn: async (payload) =>
      apiClient.post({
        endpoint: 'ajaxHandler.php',
        payload,
      }),
  });

  const form = useForm({
    resolver: zodResolver(contactSchema),
    mode: 'onChange',
    defaultValues: {
      email: leadObj.Email__c ?? '',
      phone: (leadObj.Mobile_Primary__c || '').split('-').join(''),
    },
  });

  const getFormData = localStorage.getItem('formData') ? JSON.parse(localStorage.getItem('formData')) : null;
  const [formData, setFormData] = useState(getFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [enableButton, setEnableButton] = useState(false);
  const { width } = useWindowDimensions();
  const noLead = leadObj?.First_Name__c === 'Hey';

  const handleGoogleEvent = (page, isSingle = false) => {
    const ui = localStorage.getItem('ui') ? JSON.parse(localStorage.getItem('ui')) : null;
    const uiArr = ui?.ga_single_events ? ui.ga_single_events : [];
    const googleEvent = GOOGLE_EVENT_MAPPING[page];

    if (isSingle) {
      if (!uiArr.includes(page)) {
        uiArr.push(page);
        updateLocalStorage('ui', { ga_single_events: uiArr });
        sendGoogleEvent(googleEvent);
      }
    } else {
      sendGoogleEvent(googleEvent);
    }
  };

  const breakpoints = (d_width) => {
    let val = null;
    if (d_width <= 359) {
      val = 'xxs';
    } else if (d_width >= 360 && d_width < 600) {
      val = 'xs';
    } else if (d_width >= 600 && d_width < 960) {
      val = 'sm';
    } else if (d_width >= 960 && d_width < 1280) {
      val = 'md';
    } else if (d_width >= 1280 && d_width < 1920) {
      val = 'lg';
    } else if (d_width >= 1920) {
      val = 'xl';
    }
    return val;
  };

  // eslint-disable-next-line default-param-last
  const submitLeadQualification = async (onLoad = false, callback) => {
    if (!noLead) {
      let fd = localStorage.getItem('formData') ? JSON.parse(localStorage.getItem('formData')) : null;

      const scoreMap = buildScoreMap(fd);
      const fd_stage = fd?.Stage__c ?? null;

      const splitStage = fd_stage ? fd_stage.split(' ') : null;
      let stage_score = splitStage ? `${splitStage.join('_')}_Score__c` : null;
      const fd_score = scoreMap.get(fd_stage);

      if (stage_score && fd_score) {
        if (fd_stage === CATEGORIES.ENTRANCE_EXAM) {
          stage_score = 'Entrance_Exam_Score__c';
        } else if (fd_stage === CATEGORIES.LICENSURE_EXAM || fd_stage === CATEGORIES.EXIT_EXAM) {
          stage_score = 'Exit_Exam_Score__c';
        } else if (fd_stage === CATEGORIES.CORE_RN_COURSES) {
          stage_score = 'Core_Nursing_Courses_Score__c';
        }

        updateLocalStorage('formData', { [stage_score]: fd_score });
      }

      if (fd_stage) {
        const stageArr = fd.Stages_Completed__c.split(';');
        if (!stageArr.includes(fd_stage)) {
          stageArr.push(fd_stage);
        }
        let Calendly_Appointment_Scheduled__c;
        let Reservation_Confirmed_Score__c;
        if (fd_stage === CATEGORIES.READY_SOON_RES_CONFIRMED || fd_stage === CATEGORIES.READY_NOT_YET_RES_CONFIRMED) {
          Reservation_Confirmed_Score__c = 50;
        }
        if (fd_stage === CATEGORIES.READY_SOON_RES_SCHEDULE) {
          Calendly_Appointment_Scheduled__c = true;
        }

        updateLocalStorage('formData', {
          Calendly_Appointment_Scheduled__c,
          Reservation_Confirmed_Score__c,
          Stage__c: fd_stage,
          Stages_Completed__c: fixUniqueStages(stageArr),
        });
      }

      fd = localStorage.getItem('formData') ? JSON.parse(localStorage.getItem('formData')) : null;
      const campaign = localStorage.getItem('campaign') ? JSON.parse(localStorage.getItem('campaign')) : null;
      const lid = fd.Lead__c;

      const leadInfo = {
        firstName: leadObj.First_Name__c,
        lastName: leadObj.Name,
        email: leadObj.Email__c,
        campaign,
        formName: 'Lead Qualification',
        timezone: TZ,
        lq_app: true,
      };

      const json = {
        action: 'submitLeadQualification',
        leadId: lid,
        userInfo: fd,
        leadInfo,
      };

      if (fd_stage === CATEGORIES.GENERAL_EDUCATION && fd?.ATP_6__c && fd.ATP_6__c !== 'Not important.') {
        json.leadInfo.pilot = true;
        json.updateLead = true;
      }

      if (currentPage === '0_4' || onLoad) {
        json.updateLead = true;
      }

      if (fd_stage === CATEGORIES.READY_NOT_YET_LEARN_HOME) {
        json.updateLead = true;
      }

      mutateLeadQualification(json)
        .then((res) => {
          if (!onLoad && currentObj?.meetAction) {
            handleGoogleEvent('reservation_confirmed');
            const ui = localStorage.getItem('ui') ? JSON.parse(localStorage.getItem('ui')) : null;

            if (ui?.currentOptionValue === 'I prefer to schedule at another time.') {
              handleGoogleEvent('ca');
            } else if (ui?.currentOptionValue === 'I prefer to meet with an advisor now.') {
              if (isValidTimeToMeet) {
                handleGoogleEvent('sn');
                nav(`${ROUTES.REDIRECT_TO_MEET_NOW.onlyLink}/${leadObj.token__c}`, {
                  state: { from: currentPage, dev_setPortal },
                });
              } else {
                nav(
                  currentObj.customKey === 'bpPreBuyReservation'
                    ? ROUTES.BRIDGE_PLAN_PRE_BUY_READY_IMMEDIATE_RES_SCHEDULED.link
                    : ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_IMMEDIATE_RES_SCHEDULED.link,
                  {
                    state: { from: currentPage, dev_setPortal },
                  }
                );
              }
            }
            if (callback) {
              callback();
            }
            return true;
          }

          if (callback) {
            callback();
          }
          return null;
        })
        .catch(() => {
          snackbar.error('Something went wrong please try again');
        });
    }
  };

  const handleSubmitLeadQualification = async (action, callback) => {
    const val =
      action === 'meet_now' ? 'I prefer to meet with an advisor now.' : 'I prefer to schedule at another time.';
    setIsLoading(true);
    updateLocalStorage('formData', { ATP_14__c: val });
    updateLocalStorage('ui', { currentOptionValue: val });

    await submitLeadQualification(false, callback);

    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };

  const renderCard = () => {
    let comp = null;
    if (currentObj?.pageCard) {
      comp = (
        <PageCard
          {...props}
          handleSubmitLeadQualification={handleSubmitLeadQualification}
          handleGoogleEvent={handleGoogleEvent}
          updateCategory={updateCategory}
        />
      );
    } else if (currentObj?.calendlyComponent) {
      comp = (
        <CalendlyView
          {...props}
          submitLeadQualification={submitLeadQualification}
          handleGoogleEvent={handleGoogleEvent}
          updateCategory={updateCategory}
        />
      );
    } else if (currentObj?.checkout) {
      comp = (
        <Checkout
          {...props}
          buttonIsLoading={isLoading}
          submitLeadQualification={submitLeadQualification}
          handleGoogleEvent={handleGoogleEvent}
        />
      );
    } else if (currentObj?.main) {
      console.log('<------------------------ main card ------------------------>');
      comp = <MainCard {...props} />;
    } else if (currentObj?.customBody) {
      console.log('<------------------------ customBody card ------------------------>');
      comp = <CustomBodyCard {...props} />;
    } else if (!currentObj?.main && currentObj?.informative && !currentObj?.results && !currentObj?.videoComponent) {
      console.log('<------------------------ InformativeCard card ------------------------>');
      comp = <InformativeCard {...props} />;
    } else if (currentObj?.informative && currentObj?.results) {
      console.log('<------------------------ ResultsCard card ------------------------>');
      comp = <ResultsCard {...props} />;
    } else if (currentObj?.informative && currentObj?.videoComponent) {
      console.log('<------------------------ VideoCard card ------------------------>');
      comp = (
        <VideoCard
          {...props}
          updateCategory={updateCategory}
          setEnableButton={setEnableButton}
          breakpoints={breakpoints(width)}
          handleGoogleEvent={handleGoogleEvent}
          submitLeadQualification={submitLeadQualification}
        />
      );
    } else {
      console.log('<------------------------ else card ------------------------>');
      comp = <FormCard {...props} form={form} pageCallBack={pageCallBack} setEnableButton={setEnableButton} />;
    }
    return comp;
  };

  const pageCallBack = (key, value) => {
    if (key && value) {
      setFormData({ ...formData, [key]: value });
    }
  };

  const handleClick = (e) => {
    setEnableButton(false);
    // this logic handles conditional page redirects and normal page navigation
    let hasCondition = false;
    let redirectPage = null;
    const uiObj = localStorage.getItem('ui') ? JSON.parse(localStorage.getItem('ui')) : {};
    const currentOptionValue = uiObj?.currentOptionValue ? uiObj.currentOptionValue : null;

    let selectedOption = null;
    if (currentObj?.question) {
      for (const options of currentObj.list) {
        if (options?.conditionals && options?.conditionals.length > 0) {
          for (const option of options.conditionals) {
            const { page, redirect, value } = option;
            if (value === currentOptionValue) {
              selectedOption = option;
              hasCondition = true;
              if (redirect) {
                redirectPage = page;
                break;
              }
            }
          }
        }
      }
    }

    if (currentObj.allowCategoryAndUpload) {
      // udpate current stage

      updateCategory(currentObj.category);

      handleGoogleEvent(currentPage);
      setIsLoading(true);
      submitLeadQualification('', () => {
        setIsLoading(false);
        postProcessHandleClick(hasCondition, redirectPage, selectedOption);
      });
      return;
    }

    postProcessHandleClick(hasCondition, redirectPage, selectedOption);
  };

  const postProcessHandleClick = (hasCondition, redirectPage, selectedOption) => {
    if (!hasCondition) {
      if (currentObj?.nextPage === 'HAVE_A_MEETING') {
        handleGoogleEvent('pre_buy_meetNow', true);
        handleSubmitLeadQualification('meet_now');
      } else if (
        currentObj?.nextPage === ROUTES.BRIDGE_PLAN_WO_PRE_BUY_RES.link ||
        currentObj?.nextPage === ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_NOT_YET_OPTS.link
      ) {
        setIsLoading(true);
        updateCategory(currentObj?.category);
        submitLeadQualification(false, () => {
          setIsLoading(false);
          navigate(currentObj?.nextPage);
        });
      } else {
        navigate(currentObj?.nextPage);
      }
    } else if (selectedOption?.redirectLinkType === 'external') {
      if (selectedOption?.category) {
        setIsLoading(true);
        updateCategory(selectedOption.category);
        submitLeadQualification(false, () => {
          const link = redirectPage + (selectedOption.openWebWithName ? `?l_name=${leadObj?.First_Name__c}` : '');
          openWindow(link);
        });
      } else {
        openWindow(redirectPage);
      }
    } else if (
      redirectPage === ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_NOT_YET_RES_SCHEDULED.link ||
      redirectPage === ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_SOON_RES_SCHEDULED.link
    ) {
      handleGoogleEvent('no_pre_buy_calendly', true);
      setIsLoading(true);
      updateCategory(selectedOption.category);
      handleSubmitLeadQualification('', () => {
        navigate(redirectPage);
        setIsLoading(false);
      });
    } else if (selectedOption.value === "Immediately. I'm ready to select a plan.") {
      setIsLoading(true);
      submitLeadQualification(false, () => {
        updateCategory(selectedOption.category);
        handleGoogleEvent('no_pre_buy_meetNow', true);
        handleSubmitLeadQualification('meet_now');
      });
    } else if (redirectPage === 'HAVE_A_MEETING') {
      handleGoogleEvent('pre_buy_meetNow', true);
      handleSubmitLeadQualification('meet_now');
    } else {
      navigate(redirectPage);
    }
  };

  const processPhoneNumberValidity = async (phoneNumber) => {
    return true;
    // if (!isProductionEnv) {
    //   return true;
    // }
    // let isPhoneValid = leadObj.Mobile_Primary__c.split('-').join('') === phoneNumber.split('-').join('');

    // if (!isPhoneValid) {
    //   try {
    //     const phoneValidationResponse = await validatePhoneNumber({
    //       number: phoneNumber,
    //       country_code: 'US',
    //     });
    //     if (phoneValidationResponse?.Status?.toLowerCase() === 'valid') {
    //       isPhoneValid = true;
    //     } else {
    //       form.setError('phone', { type: 'manual', message: 'Please provide valid phone number' });
    //     }
    //   } catch (e) {
    //     console.error(e);
    //     // snackbar.error('There was an error validating your phone number.');
    //   }
    // }

    // return isPhoneValid;
  };

  const submitLeadContact = async () => {
    setIsLoading(true);
    const fd = localStorage.getItem('formData') ? JSON.parse(localStorage.getItem('formData')) : null;
    const lid = fd.Lead__c;

    const userInfo = {
      Id: lid,
      Email__c: fd.Email__c,
      Mobile_Primary__c: fd.mobile,
    };

    // Validate phone number
    const isPhoneValid = await processPhoneNumberValidity(userInfo.Mobile_Primary__c);
    if (!isPhoneValid) {
      setIsLoading(false);
      snackbar.error('The phone number you entered is invalid. Please try again.');
      return false;
    }

    try {
      await mutateUpdateLead({
        action: 'udpateLead',
        leadId: lid,
        userInfo,
      });
      return true;
    } catch (e) {
      snackbar.error('');
      return false;
    }
  };

  const handleSubmission = async (api) => {
    setIsLoading(true);
    switch (api) {
      case 'contact_form':
        {
          form.trigger(['email', 'phone']);
          if (!form.formState.isValid) {
            setIsLoading(false);
            return;
          }

          const isSuccess = await submitLeadContact();
          if (!isSuccess) {
            return;
          }
          updateCategory(currentObj.category);
          submitLeadQualification(false, () => {
            setIsLoading(false);
            navigate(ROUTES.BRIDGE_RESULTS.link);
          });
        }
        break;

      case 'ATP_13__c':
        submitLeadQualification();
        break;
      default:
        break;
    }
    return null;
  };

  const handleDisableButton = () => {
    const fd = localStorage.getItem('formData') ? JSON.parse(localStorage.getItem('formData')) : null;
    // conditions to ignore disabling the button
    if (
      (currentObj?.informative && !currentObj?.disableButton) ||
      currentObj?.api === 'contact_form' ||
      currentObj?.enableButton
    ) {
      return false;
    }
    // iterate over the formData to see if value exists
    if (fd) {
      for (const [key, value] of Object.entries(fd)) {
        if (key === currentObj?.api && value !== '') {
          return false;
        }
      }
    }
    return true;
  };

  const handleProgress = (a, b) => {
    if (a && b) {
      return b > 0 ? (a / b) * 100 : 0;
    }
    return null;
  };

  const handlePageCount = () => {
    const locallyStoredFormData = localStorage.getItem('formData');
    const formData = locallyStoredFormData ? JSON.parse(locallyStoredFormData) : null;

    const arr = new Set();
    const ignoreQuestions = new Set(['ATP_3__c']);
    const ignoreAPImap = new Map([
      ['ATP_5__c', 'ATP_6__c'],
      ['ATP_7__c', 'ATP_8__c'],
      ['ATP_9__c', 'ATP_10__c'],
      ['ATP_11__c', 'ATP_12__c'],
    ]);

    let i = 1;
    while (i < 13) {
      const labelValue = formData[`ATP_${i}__c`];
      if (
        labelValue !== undefined &&
        labelValue &&
        labelValue.includes(`I've passed`) &&
        labelValue !== `I've passed some, but not all of them.`
      ) {
        if (ignoreAPImap.get(`ATP_${i}__c`) != null) {
          ignoreQuestions.add(ignoreAPImap.get(`ATP_${i}__c`));
        }
      }
      i++;
    }

    let j = 1;
    while (j < 13) {
      if (!ignoreQuestions.has(`ATP_${j}__c`)) {
        arr.add(`ATP_${j}__c`);
      }
      j++;
    }
    const nOfQuestions = arr.size;
    let nOfQuestionsCompleted = 0;

    if (formData) {
      for (const [key, value] of Object.entries(formData)) {
        if (arr.has(key) && value !== '') {
          nOfQuestionsCompleted++;
        }
      }
    }
    return [nOfQuestionsCompleted, nOfQuestions];
  };

  const handleRenderPageCount = () => {
    let html = null;
    if (currentObj?.checkout) {
      html = null;
    } else if (currentObj?.step) {
      html = currentObj?.step;
    } else if (currentObj?.main || currentObj?.hidePageCount) {
      html = null;
    } else {
      const n_pageCount = pageCount[0] === 0 ? 1 : pageCount[0];
      html = `question ${n_pageCount}/${pageCount[1]}`;
    }
    return html;
  };

  const handleShowBackButton = () => {
    return currentPage !== ROUTES.QUALIFY.link;
  };

  const updateCategory = (str) => {
    if (str) {
      updateLocalStorage('formData', { Stage__c: str });
      return true;
    }
    return false;
  };

  const disableButton = handleDisableButton();
  const showBackButton = handleShowBackButton();
  const pageCount = handlePageCount();

  const progress = handleProgress(pageCount[0], pageCount[1]);
  const renderPageCount = handleRenderPageCount();
  const showDefaultProgress = currentObj?.defaultProgress;

  useEffect(() => {
    handleGoogleEvent('start_qualify');
    submitLeadQualification(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, formData]);

  return (
    <article className="flex h-svh max-h-[100vh] flex-col bg-primary-x-light">
      <main className={`flex-1 ${currentObj?.results ? 'results' : ''} ${currentObj.backgroundColor}`}>
        <section className={`h-full p-8 ${currentObj.calendlyComponent ? 'calendly' : ''}`}>
          <header className="flex h-16 justify-between">
            <div>
              {showBackButton && !currentObj?.hideBackButton && (
                <Button
                  as="link"
                  theme="primary"
                  className="hover:bg-accent-light/10 focus:ring-0 disabled:border-primary-light disabled:bg-primary-light disabled:text-primary-medium"
                  onClick={(e) => nav(-1)}
                  iconLeft={ChevronLeftIcon}
                >
                  {renderPageCount && renderPageCount !== ' ' && (
                    <div className={!showDefaultProgress ? 'text-secondary' : ''}>{renderPageCount}</div>
                  )}
                </Button>
              )}
            </div>

            <div>
              {(currentObj?.main || currentObj?.results) && !currentObj?.darklogo ? (
                <LightLogo className="w-7 md:w-10" />
              ) : (
                <DarkLogo className="w-7 md:w-10" />
              )}
            </div>
          </header>

          <div
            className={`mx-auto my-0 w-[37.3333333333rem] max-w-full ${currentObj.calendlyComponent ? 'calendly' : ''}`}
          >
            {renderCard(currentPage, currentObj)}
          </div>
        </section>
      </main>

      <footer
        className={`flex flex-col items-center justify-center px-4 py-8 md:px-10 ${
          currentObj?.hideFooter ? 'hidden' : null
        } ${currentObj?.footerColor ?? 'bg-primary-xx-light'}`}
      >
        <Button
          onClick={handleClick}
          disabled={!currentObj?.videoComponent && !enableButton && disableButton}
          size="lg"
          theme="primary"
          className={`w-48 rounded-none text-xl font-bold disabled:border-primary-light disabled:bg-primary-light disabled:text-primary-medium ${
            currentObj?.isSubmission ? 'hidden' : ''
          }`}
          showLoader={isLoading}
          iconRight={currentObj?.hideButtonIcon ? null : ArrowRightIcon}
        >
          {currentObj.buttonText}
        </Button>

        <Button
          disabled={disableButton && !enableButton}
          showLoader={isLoading}
          theme="primary"
          size="lg"
          onClick={() => handleSubmission(currentObj?.api)}
          iconRight={currentObj?.hideButtonIcon ? null : ArrowRightIcon}
          className={`w-48 rounded-none text-xl font-bold disabled:border-primary-light disabled:bg-primary-light disabled:text-primary-medium ${
            currentObj?.isSubmission ? '' : 'hidden'
          }`}
        >
          {currentObj?.buttonText}
        </Button>

        {/* {buttonInfo} */}

        <section className={`w-[37.3333333333rem] max-w-full ${currentObj?.hideProgress ? 'hidden' : ''}`}>
          <ProgressBar value={progress} label="" main currentObj={currentObj} pageCount={pageCount} defaultProgress />
        </section>
      </footer>
    </article>
  );
}

function fixUniqueStages(strArr) {
  let arr = strArr;
  if (typeof strArr === 'string') {
    arr = strArr.split(';');
  }
  arr = arr.map((i) => i.trim());
  return [...new Set(arr)].join(';');
}
