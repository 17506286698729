import { Skeleton } from 'atp-react-ui';

import { ReactComponent as CalendarIcon } from '_static/assets/calendarIcon.svg';

export function Loading() {
  return (
    <>
      <div className="flex md:mt-[3px] md:-ml-[3px] self-center relative">
        <CalendarIcon />
        <div className="flex flex-col gap-1 items-center pl-6 pt-12 absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
          <Skeleton className="h-6 w-14" />
          <Skeleton className="h-6 w-24" />
        </div>
      </div>
      <div className="flex flex-col gap-3 mt-4 items-center md:items-start md:pr-16">
        <Skeleton className="h-6 w-2/3 md:w-64" />
        <Skeleton className="h-8 w-3/4 md:w-96" />
        <Skeleton className="h-8 w-3/4 md:w-96" />
        <Skeleton className="h-6 w-2/3 md:w-64" />
      </div>
    </>
  );
}
