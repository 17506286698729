import { useSuspenseQuery } from '@tanstack/react-query';

import { apiClient } from '_utils';

export const useLeadQuery = (payload, options) =>
  useSuspenseQuery({
    ...options,
    queryKey: ['LEAD_INFO', payload],
    queryFn: async () =>
      apiClient.post({
        endpoint: 'ajaxHandler.php',
        payload,
      }),
  });
