import { SUPPORTED_ENVIRONMENT } from '_static/constants';

export const {
  REACT_APP_API_BASE_URL,
  REACT_APP_CONNECTED_PORTAL_URL,
  REACT_APP_ENVIRONMENT,
  REACT_APP_STAGING,
  REACT_APP_CALENDER_URL,
  REACT_APP_PRE_BUY_CALENDER_URL,
} = process.env;

export const isDevelopmentEnv = REACT_APP_ENVIRONMENT === SUPPORTED_ENVIRONMENT.DEVELOPMENT;
export const isStagingEnv = REACT_APP_ENVIRONMENT === SUPPORTED_ENVIRONMENT.STAGING;
export const isProductionEnv = REACT_APP_ENVIRONMENT === SUPPORTED_ENVIRONMENT.PRODUCTION;

export const calendlyCalendarUrl = REACT_APP_CALENDER_URL;
export const calendlyPreBuyUrl = REACT_APP_PRE_BUY_CALENDER_URL;

export const BRIDGEPLANID = !isProductionEnv ? 'price_1JUvGYF8SuaCizQfPpAasPBS' : 'price_1JUvGyF8SuaCizQfjVh8ch2H';

export const FILTER = new Set([
  'ATP_1__c',
  'ATP_2__c',
  'ATP_3__c',
  'ATP_4__c',
  'Lead__c',
  'Stage__c',
  'Email__c',
  'General_Education_Score__c',
  'ATP_Reject__c',
]);

export const DEVICEWIDTH = document.innerHeight;
export const TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
