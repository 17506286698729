import dateTime from './date-time';

export function getTimeValidity(dev_setPortal) {
  const startTime = dateTime.getDeviceTimeInEST().hour(8).minute(55).second(0);
  const endTimeWeekDays = dateTime.getDeviceTimeInEST().hour(21).minute(0).second(0);
  const endTimeSat = dateTime.getDeviceTimeInEST().hour(14).minute(0).second(0);

  const currentTimeInET = dateTime.getDeviceTimeInEST();
  const endTime = currentTimeInET.get('day') === 6 ? endTimeSat : endTimeWeekDays;

  let isValidTimeToMeet = false;
  if (currentTimeInET.get('day') !== 0 && dateTime.isInBetween(currentTimeInET, { startTime, endTime })) {
    isValidTimeToMeet = true;
  }

  /**
   * Provide dev_setPortal=on|off|undefined into url
   */
  if (dev_setPortal === 'on') {
    isValidTimeToMeet = true;
  } else if (dev_setPortal === 'off') {
    isValidTimeToMeet = false;
  }

  const isValidTimeToChat = isValidTimeToMeet && currentTimeInET.get('day') !== 6 && currentTimeInET.get('day') !== 0;

  return { isValidTimeToChat, isValidTimeToMeet };
}
