import ROUTES from '_navigation/routes';

import CATEGORIES from './category-mapper';

const CATEGORY_TO_RESUME_PATH_MAPPER = [
  {
    category: CATEGORIES.START_QUALIFY,
    link: ROUTES.QUALIFY.link,
  },
  {
    category: CATEGORIES.MINDSET_QUALIFY,
    link: ROUTES.BRIDGE_START.link,
  },
  {
    category: CATEGORIES.GENERAL_EDUCATION,
    link: ROUTES.BRIDGE_ENTRANCE_EXAM.link,
  },
  {
    category: CATEGORIES.ENTRANCE_EXAM,
    link: ROUTES.BRIDGE_CORE_NURSING.link,
  },
  // Legacy: Core Nursing Courses
  {
    category: CATEGORIES.CORE_NURSING_COURSES,
    link: ROUTES.BRIDGE_EXIT_EXAM.link,
  },
  {
    category: CATEGORIES.CORE_RN_COURSES,
    link: ROUTES.BRIDGE_EXIT_EXAM.link,
  },
  // Legacy: Core Nursing Courses
  // Legacy: Exit Exam
  {
    category: CATEGORIES.LICENSURE_EXAM,
    link: ROUTES.BRIDGE_CONFIRM_CONTACT.link,
  },
  {
    category: CATEGORIES.ENTRANCE_EXAM,
    link: ROUTES.BRIDGE_CONFIRM_CONTACT.link,
  },
  // Legacy: Exit Exam
  {
    category: CATEGORIES.RESULT_QUALIFY,
    link: ROUTES.BRIDGE_PLAN_NEXT_STEP.link,
  },
  {
    category: CATEGORIES.BP_INFO,
    link: ROUTES.BRIDGE_PLAN_PRE_BUY.link,
  },
  {
    category: CATEGORIES.BP_PRE_BUY,
    link: ROUTES.BRIDGE_PLAN_PRE_BUY.link,
  },
  {
    category: CATEGORIES.READY_NOT_YET_VIDEO,
    link: ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY.link,
  },
  {
    category: CATEGORIES.BP_PRE_BUY_NO,
    link: ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY.link,
  },
  // {
  //   category: CATEGORIES.BP_PRE_BUY_YES,
  //   link: ROUTES.BRIDGE_PLAN_PRE_BUY.link,
  // },

  // legacy should not hit this as already being handled
  // {
  //   category: CATEGORIES.BRIDGE_PLAN_PURCHASED,
  //   link: ROUTES.BRIDGE_PLAN_PRE_BUY_PURCHASED.link,
  // },
  // legacy
  {
    category: CATEGORIES.BP_PRE_BUY_PURCHASED,
    link: ROUTES.BRIDGE_PLAN_PRE_BUY_PURCHASED.link,
  },

  {
    category: CATEGORIES.MINDSET_REPATH_NOT_READY,
    link: ROUTES.MINDSET_Q1.link,
  },
  // legacy
  {
    category: CATEGORIES.GENERAL_EDUCATION_REPATH_NOT_READY,
    link: ROUTES.BRIDGE_START.link,
  },
  {
    category: CATEGORIES.GEN_ED_REPATH_PASSED_ALL,
    link: ROUTES.BRIDGE_START.link,
  },
  // legacy
  {
    category: CATEGORIES.REPATH_PASSED_ENTRANCE_EXAM,
    link: ROUTES.BRIDGE_ENTRANCE_EXAM.link,
  },

  {
    category: CATEGORIES.REPATH_PASSED_CORE_RN_COURSES,
    link: ROUTES.BRIDGE_CORE_NURSING.link,
  },

  {
    category: CATEGORIES.REPATH_PASSED_EXIT_EXAM,
    link: ROUTES.BRIDGE_EXIT_EXAM.link,
  },
];

// in case of undefined send to result
export default CATEGORY_TO_RESUME_PATH_MAPPER;
