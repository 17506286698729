// all global settings

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const GOOGLE_ANALYTICS_ID = 'UA-23223595-3';

export const SUPPORTED_ENVIRONMENT = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
  STAGING: 'staging',
  LOCAL: 'local',
};
