// info: All APIs and their settings
const API_CALLS = (() => {
  return {
    login: {
      key: 'login',
      api: '/token/',
      type: 'post',
      isAuthenticationReq: false,
      setSpinner: true,
      setCancelBtn: true,
      from: 0,
      priority: 'normal',
      dto: { username: '', password: '' },
    },

    getProfile: {
      key: 'getProfile',
      api: '/me/',
      type: 'get',
      isAuthenticationReq: true,
      from: 0,
      priority: 'normal',
      dto: {},
    },
  };
})();

export default API_CALLS;

window.apis = API_CALLS;
